export enum Events {
    Sports = '1,2,4',
    Races = '7,4339',
    Casino = '99999,99995,99998',
    Casino1 = '99999',
    ICasino = '99995',
    IntCasino = '99998',
    RoyalCasino = '90909090',
    Binary = '99990',
    SportsBook = '99991',
}

export enum EventsName {
    ICasino = 'INT.Casino',
    Casino1 = 'CARDS',
    SportsBook = 'Premium Sportsbook',
}
