export const environment = {
  production: false,
  panel: "radheexchBackend_",
  domain: "parker777.com",
  baseUrl: "https://api.parker777.com",
  pollers: {
      oddsData: 600,
      listOddsData: 10000,
      scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  imgPrefix: '/radheexchBackend_client',
  guestEnable: false,
  modalClass: 'bookModal',
  darkMode: false,
  userList: [
      { role_id: 1, id: 'admin', name: 'Admin', cIds: [], lIds: [] },
      { role_id: 2, id: 'super_master', name: 'Super Master', cIds: [1, 2], lIds: [1, 2] },
      { role_id: 3, id: 'master', name: 'Master', cIds: [1, 2], lIds: [1, 2] },
      { role_id: 4, id: 'client', name: 'Client', cIds: [1, 2, 3], lIds: [1, 2, 3] },
  ],
  hidePartenerShips: [9994],
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  host: {
      domain: '',
      name: '',
      dName: '',
      ext: '',
      theme: '',
      email: '',
      themePath: [''],
      pusher: {
          key: '',
          cluster: ''
      },
      ftLivetvData: '',
      guestEnable: false,
  },
  hosts: [
      {
          domain: 'localhost',
          name: 'localhost',
          dName: 'LocalHost',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          themePath: ['assets/css/theme-files/theme-master.css'],
          pusher: {
              key: 'c8bbdb36496c73b41567',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
      },
      {
          domain: 'radhexchange.com',
          name: 'radhexchange',
          dName: 'admin.radhexchange.com',
          ext: '.com',
          theme: 'blue-body',
          email: '',
          themePath: ['assets/css/theme-files/theme-master.css'],
          pusher: {
              key: '76d7c55c2b5f8747ffd4',
              cluster: 'eu'
          },
          ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
          guestEnable: true,
      },
      {
        domain: 'radheexch.me',
        name: 'radheexch',
        dName: 'admin.radheexch.com',
        ext: '.me',
        theme: 'blue-body',
        email: '',
        themePath: ['assets/css/theme-files/theme-master.css'],
        pusher: {
            key: '76d7c55c2b5f8747ffd4',
            cluster: 'eu'
        },
        ftLivetvData: 'https://e765432.xyz/static/cc6c487c44b971286c3d0d1e24649619b4681674/getdata.php?chid=',
        guestEnable: true,
    }
  ]
};
