<div class="container-fluid container-padding-0">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
      <div class="main-box">
        <h5 class="text-blue">Change Password</h5>
        <div class="row supermaster mt-3">
          <div class="col-12 col-sm-12 col-md-6 col-lg-4">
            <form [formGroup]="formData" (ngSubmit)="onSubmit()">
              <div class="form-group col-12">
                <label for="name">Old Password:</label>
                <input [type]="oldPasswordTextType ? 'text' : 'password'" class="form-control" placeholder="Old Password..." formControlName="old_password" [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }" />
                <span class="changePasscode-visible" (click)="toggleOldPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !oldPasswordTextType, 'fa-eye-slash': oldPasswordTextType }" aria-hidden="true"></i></span>
                <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                  <div *ngIf="f.old_password.errors.required">The Old Password field is required</div>
                </div>
                <div *ngIf="errors?.old_password" class="invalid-feedback apiError">
                    <div *ngFor="let item of errors?.old_password;trackBy:trackByIndex;">{{item}}</div>
                </div>
                <span class="fst-normal small text-muted help-text">[A-Z , a-z , 0-9 , @  ] Allowed</span>
              </div>
              <div class="form-group col-12 change-password-valid">
                <label for="name">New Password:</label>
                <input [type]="newPasswordTextType ? 'text' : 'password'" class="form-control" placeholder="New Password..." formControlName="new_password" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }" />
                  <span class="changePasscode-visible" (click)="toggleNewPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !newPasswordTextType, 'fa-eye-slash': newPasswordTextType }" aria-hidden="true"></i></span>
                  <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                    <div *ngIf="f.new_password.errors.required">The New Password field is required</div>
                    <div *ngIf="f.new_password.errors.minlength">The New Password field must be at least 6 characters</div>
                    <div *ngIf="!f.new_password.errors.minlength && f.new_password.errors.pattern">
                        The New Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number
                    </div>
                  </div>
                  <div *ngIf="errors?.new_password" class="invalid-feedback apiError">
                      <div *ngFor="let item of errors?.new_password;trackBy:trackByIndex;">{{item}}</div>
                  </div>
                  <span class="fst-normal small text-muted help-text">[A-Z , a-z , 0-9 , @  ] Allowed</span>
              </div>
              <div class="form-group col-12">
                <label for="name">Retype Password:</label>
                <input [type]="confirmPasswordTextType ? 'text' : 'password'" class="form-control" placeholder="Confirm Password..." formControlName="new_password_re" [ngClass]="{ 'is-invalid': submitted && f.new_password_re.errors }" />
                <span class="changePasscode-visible" (click)="toggleConfirmPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !confirmPasswordTextType, 'fa-eye-slash': confirmPasswordTextType }" aria-hidden="true"></i></span>
                <div *ngIf="submitted && f.new_password_re.errors" class="invalid-feedback">
                  <div *ngIf="f.new_password_re.errors.required">The Confirm Password field is required</div>
                  <div *ngIf="f.new_password_re.errors.mustMatch">The Confirm Password confirmation does not match</div>
                </div>
                <div *ngIf="errors?.new_password_re" class="invalid-feedback apiError">
                    <div *ngFor="let item of errors?.new_password_re;trackBy:trackByIndex;">{{item}}</div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <div class="text-right"><button type="submit" [disabled]="isLoad" class="btn btn-blue accbtn mt-0">Change Password</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>