export const api = {
    login: '/api/backend/auth',
    logout: '/api/logout',
    refreshToken: '/api/refresh',
    eventList: '/api/backend/events',
    eventListNew: '/api/client/event_list',
    raceList: '/api/backend/races',
    blockEvent:'/block-event',
    unBlockEvent:'/unblock-event',
    eventDetail: '/api/backend/events',
    eventDetailOnly: '/api/backend/event_detail',
    odds: '/ws/getMarketData',
    oddsNew: '/ws/getMarketDataNew',
    exposure: '/api/backend/expose-details',
    accountStmt: '/api/backend/report/statement',
    fancyRunPosition: '/api/backend/fancy_run_position',
    matchOddBook: '/api/backend/match_odds/book',
    bookMakerBook: '/api/backend/book_maker/book',
    scoreData: '/ws/getScoreData',
    tvData: '/api/backend/stream',
    placeBet: '/api/backend/store_order',
    orderList: '/api/backend/orders',
    stakesUpdate: '/api/backend/update_stakes',
    casino1: '/api/backend/casino_1',
    casinoIList: '/api/backend/casino_i_list_new',
    casinoITbl: '/api/backend/casino_i_new',
    casinoIntTbl: '/api/client/casino_int_new',
    changePass: '/api/change_password',
    forceChangePass: '/api/backend/users/force_reset_password',
    myProfile: '/api/backend/profile',
    rules: '/api/backend/rules',
    checkUsername: '/check-username',
    eventTypeIds:'/api/backend/event-type-id/list',
    marketSettings: '/api/backend/user_market_setting',
    userData: '/ws/getUserData',
    dashboard: '/api/backend/stats-summery',

    eventListG: '/api/guest/events',
        raceListG: '/api/guest/races',
        eventDetailG: '/api/guest/event',
        casino1G: '/api/guest/casino_1',
    menu: '/api/guest/menu',
    marketAnalysis: '/api/backend/market-analysis',
    multiMarket: '/api/backend/multi-market',
    saveMultiMarket: '/save',
    removeMultiMarket: '/remove',

    superMaster: '/api/backend/users/child_by_type',
    myChilds: '/api/backend/users/child',
    blockChild: '/block-children',
    childByType: '/child_by_type',
    user: '/api/backend/users',
    updateStatus: '/update-status',
    updateBetLock: '/update-order-lock',
    updateRemove: '/update-removed',
    resetPassword: '/reset_password',
    getCreateUser:'/create-user',
    createUser:'/store-user',
    removeChild: '/removed-child',
    undoRemoveUser: '/update-removed',
    marketAnalsis: '/api/backend/market-analysis',

    bets: '/api/backend/bets',
    unsettledBets: '/unsettled-orders',
    accStmt: '/api/backend/account/statement',
    accStmtNew: '/api/backend/account/statement-new',
    chipStmt: '/api/backend/account/chip-statement',
    chipStmtNew: '/api/backend/account/chip-statement-new',
    chipSmry: '/api/backend/account/chip_report',
    profitLoss: '/api/backend/account/profit-loss',
    profitLossDetail: '/api/backend/account/profit-loss/detail',
    profitLossSummary: '/api/backend/account/profit-loss/summery-event',
    sportReport: '/sport-report',
    betHistory: '/bet-history',
    topClient: '/api/backend/top-client',
    currentUserPnlSummary: '/current-outstanding',
    settlement: '/chip-summery',
    headerPL: '/chip-summery',
    balanceSheet: '/balance-sheet',
    updateSettlement: '/settlement',
    getBankSettlement: '/bank-settlement-data',
    updateBankSettlement: '/save-bank-settlement',
    pendingExpose:'/api/backend/pending-expose',

    amountDeposit: '/deposit',
    amountWithdraw: '/withdraw',
    bankAccDeposit: '/bank-deposit',
    bankAccWithdraw: '/bank-withdraw',
    getEditProfile: '/edit-profile',
    updateProfile: '/update-profile',

    getUserSetting: '/config',
    getCasinoSetting: '/casino-config',
    getICasinoSetting: '/i-casino-config',
    getSportSetting: '/sports-setting',
    saveUserSetting: '/save-config',
    saveCasinoSetting: '/save-casino-config',
    saveICasinoSetting: '/save-i-casino-config',
    saveSportSetting: '/save-sports-setting',
};
